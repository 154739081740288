/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

const routes=[
  [
    {
      path: '/',
      exact: true,
      component: () => <Redirect to="/documents" />
    },
    {
      path: '/errors',
      component: ErrorLayout,
      routes: [
        {
          path: '/errors/error-401',
          exact: true,
          component: lazy(() => import('src/views/Error401'))
        },
        {
          path: '/errors/error-404',
          exact: true,
          component: lazy(() => import('src/views/Error404'))
        },
        {
          path: '/errors/error-500',
          exact: true,
          component: lazy(() => import('src/views/Error500'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      route: '*',
      component: DashboardLayout,
      routes: [
        {
          path: '/',
          exact: true,
          component: lazy(() => import('src/views/ListCompany'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    }
  ],
  [
    {
      path: '/',
      exact: true,
      component: () => <Redirect to="/company" />
    },
    {
      path: '/errors',
      component: ErrorLayout,
      routes: [
        {
          path: '/errors/error-401',
          exact: true,
          component: lazy(() => import('src/views/Error401'))
        },
        {
          path: '/errors/error-404',
          exact: true,
          component: lazy(() => import('src/views/Error404'))
        },
        {
          path: '/errors/error-500',
          exact: true,
          component: lazy(() => import('src/views/Error500'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      route: '*',
      component: DashboardLayout,
      routes: [
        {
          path: '/company',
          exact: true,
          component: lazy(() => import('src/views/ListCompany'))
        },
        {
          path: '/sensors',
          exact: true,
          component: lazy(() => import('src/views/ListSensor'))
        },
        {
          path: '/mersh-sensor/:pruebaid',
          exact: true,
          component: lazy(() => import('src/views/MershSensor'))
        },
        {
          path: '/entry/:companyid',
          exact: true,
          component: lazy(() => import('src/views/ListEntry'))
        },
        {
          path: '/ensayo/:entryid',
          exact: true,
          component: lazy(() => import('src/views/ListEnsayo'))
        },
        {
          path: '/prueba/:ensayoid',
          exact: true,
          component: lazy(() => import('src/views/ListPrueba'))
        },
        {
          path: '/pruebas',
          exact: true,
          component: lazy(() => import('src/views/ListPrueba2'))
        },
        {
          path: '/users',
          exact: true,
          component: lazy(() => import('src/views/ListUsers'))
        },
        {
          path: '/add-company',
          exact: true,
          component: lazy(() => import('src/views/AddCompany'))
        },
        {
          path: '/add-entry/:companyid',
          exact: true,
          component: lazy(() => import('src/views/AddEntry'))
        },
        {
          path: '/add-ensayo/:entryid',
          exact: true,
          component: lazy(() => import('src/views/AddEnsayo'))
        },
        {
          path: '/add-prueba/:ensayoid',
          exact: true,
          component: lazy(() => import('src/views/AddPrueba'))
        },
        {
          path: '/add-user',
          exact: true,
          component: lazy(() => import('src/views/AddUser'))
        },
        {
          path: '/edit-company/:companyid',
          exact: true,
          component: lazy(() => import('src/views/EditCompany'))
        },
        {
          path: '/edit-entry/:entryid',
          exact: true,
          component: lazy(() => import('src/views/EditEntry'))
        },
        {
          path: '/edit-ensayo/:ensayoid',
          exact: true,
          component: lazy(() => import('src/views/EditEnsayo'))
        },
        {
          path: '/edit-prueba/:pruebaid',
          exact: true,
          component: lazy(() => import('src/views/EditPrueba'))
        },
        {
          path: '/edit-client/:userid',
          exact: true,
          component: lazy(() => import('src/views/EditUser'))
        },
        {
          path: '/prueba/data/:pruebaid',
          exact: true,
          component: lazy(() => import('src/views/Device'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    }
  ],
  [
    {
      path: '/',
      component: AuthLayout,
      routes: [
        {
          path: '/auth/login',
          exact: true,
          component: lazy(() => import('src/views/Login'))
        },
        {
          path: '/auth/register',
          exact: true,
          component: lazy(() => import('src/views/Register'))
        },
        {
          component: () => <Redirect to="/auth/login" />
        }
      ]
    }
  ]
]
export default routes

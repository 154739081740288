
import BarChartIcon from '@material-ui/icons/BarChart';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';

export default [
  {
    items: [
      {
        title: 'Empresas',
        href: '/company',
        icon: HomeIcon,
        items: [
          {
            title: 'Empresas',
            href: '/company'
          },
          {
            title: 'Agregar Empresa',
            href: '/add-company'
          },
        ]
      },
      {
        title: 'Usuarios',
        href: '/company',
        icon: BarChartIcon,
        items: [
          {
            title: 'Usuarios',
            href: '/users'
          },
          {
            title: 'Agregar Usuario',
            href: '/add-user'
          },
        ]
      },
      {
        title: 'Sensores',
        href: '/sensors',
        icon: FolderIcon,
        items: [
          {
            title: 'Sensores',
            href: '/sensors'
          },
          {
            title: 'Pruebas activas',
            href: '/pruebas'
          }
        ]
      }
    ]
  },
];
